







































import Vue, { PropType } from "vue";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";

export default Vue.extend({
  name: "IndividualCourse",
  props: {
    course: {
      type: Object as PropType<UpskillCourses.UpskillCourseDetail>,
      required: true
    }
  }
});
